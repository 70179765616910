import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 

import { UserAuditsService } from '@app/_services/userAudits.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { UserAuditWithType } from '@app/_types/userAudits.types';
import { AUDIT_TYPE_CODES } from '@nurtureboss/common/dist/types/auditType'; 

@Component({ 
  selector: 'app-user-audits-widget', 
  templateUrl: 'user-audits-widget.component.html', 
  styleUrls: ['user-audits-widget.component.less'],
})
export class UserAuditsWidgetComponent implements OnInit {
  showOverlay: boolean = false;
  userAudits: UserAuditWithType[] = [];
  
  constructor(
    private userAuditsService: UserAuditsService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.userAuditsService.userAudits.subscribe(userAudits => {
      this.userAudits = userAudits.map(userAudit => {
        return {
          ...userAudit,
          icon: this.getUserAuditFAIcon(userAudit.auditType.code),
        }
      });
    })
  }

  ngOnInit(): void {
    if (!this.authService.currentUserValue || !this.authService.currentUserValue.user) {
      return;
    }
    this.userAuditsService.refreshTriggeredUserAudits();
  }

  toggleOverlay(): void {
    this.showOverlay = !this.showOverlay;
  }

  onUrlClick(url: string): void {
    this.router.navigateByUrl(url);
  }

  getUserAuditFAIcon(code: AUDIT_TYPE_CODES): string {
    if (code.toLowerCase().includes('automation'))  {
      return 'fa-robot';  
    }

    if (
      code == AUDIT_TYPE_CODES.ENABLE_RESIDENT_SOURCE
      || code == AUDIT_TYPE_CODES.ENABLE_PROSPECT_SOURCE
      || code == AUDIT_TYPE_CODES.ENABLE_AVAILABILITY_SOURCE
      || code == AUDIT_TYPE_CODES.ENABLE_TOUR_SCHEDULING_SOURCE
    ) {
      return 'fa-sync-alt';
    }
    
    if (code == AUDIT_TYPE_CODES.ENABLE_VALID_OPT_IN_SOURCES) {
      return 'fa-file-contract';
    };

    if (code == AUDIT_TYPE_CODES.MISSING_DEFAULT_FIELDS_ALERT) {
      return 'fa-square-check';
    }
  
    throw new Error(`No FA Icon found for code ${code}.`);
  };
}