import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  createEmail(emailData) {
    return this.http.post<any>(this.apiHelper.fillUrl('emails', {}, {}), emailData)
      .pipe(map(data => {
        return data;
      }), catchError(e => {
        return of({error: e})
      })
    );
  }

  createEmailsInBulk(emailDataList) {
    return this.http.post<any>(this.apiHelper.fillUrl('createBulkEmails', {}, {}), emailDataList)
      .pipe(map(data => {
        return data;
      }));
  }

  sendEmail(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendEmail', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  sendEmailTemplate(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendEmailTemplate', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  sendBulkEmails(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendBulkEmails', {}, {quickReturn:true}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  scheduleEmailSend(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('scheduleEmail', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getAllEmails(skip, take, query?, property?, startingDate?, endingDate?) {
    return this.http.get<any>(this.apiHelper.fillUrl('getEmails', {}, {
      skip: skip || 0,
      take: take || 25,
      query: query || '',
      property: property || '',
      startingDate: startingDate || '',
      endingDate: endingDate || '',
    }))
      .pipe(map(data => {
        return data;
      }));
  }

  /**
   * Creates an email forwarding route for a property.
   * 
   * @param newAccountId - The user id of the new account being created.
   * @param propertyName - The name of the property that needs the email route.
   * @param emailAddress - The primary email address for the property.
   * @returns - Fails if the route creation fails.
   */
  createForwardingRoute(newAccountId, propertyName, emailAddress) {
    return this.http.post<any>(this.apiHelper.fillUrl('createEmailRoute', {}, {}), {
      propertyName,
      forwardAddress: emailAddress,
      newAccountId,
    });
  }
}
