import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { BuildYourOwnAutomation } from '../byoa/byoa.types';

@Injectable({
  providedIn: 'root'
})
export class AutomationSettingsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getAutomationSettings(userId) {
    return this.http.get < any > (this.apiHelper.fillUrl('automationSettings', {
        userId: userId
      }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateSources(userId, sources) {
    return this.http.put < any > (this.apiHelper.fillUrl('automationSettingSources', {
        userId: userId
      }, {}), {
        sources: sources
      })
      .pipe(map(data => {
        return data;
      }));
  }

  getMessagesSentForStep(stepId) {
    return this.http.get < any > (this.apiHelper.fillUrl('automationsSent', {
        stepId: stepId
      }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getBuildYourOwnAutomations() {
    return this.http.get < {
        result ? : BuildYourOwnAutomation[],
        errors ? : any
      } > (this.apiHelper.fillUrl('byoa', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getSingleBuildYourOwnAutomation(id) {
    return this.http.get < {
        result ? : BuildYourOwnAutomation,
        errors ? : any
      } > (this.apiHelper.fillUrl('singleByoa', {
        id
      }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  createBuildYourOwnAutomation(automation, templateDefaults) {
    return this.http.post < {
        result ? : BuildYourOwnAutomation,
        errors ? : any
      } > (this.apiHelper.fillUrl('byoa', {}, {}), {
        automation,
        templateDefaults
      })
      .pipe(map(data => {
        return data;
      }));
  }

  updateBuildYourOwnAutomation(id, automation, templateDefaults) {
    return this.http.put < {
        result ? : BuildYourOwnAutomation,
        errors ? : any
      } > (this.apiHelper.fillUrl('singleByoa', {
        id
      }, {}), {
        automation,
        templateDefaults
      })
      .pipe(map(data => {
        return data;
      }));
  }

  deleteBuildYourOwnAutomation(id) {
    return this.http.delete < any > (this.apiHelper.fillUrl('singleByoa', {
        id
      }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  testBuildYourOwnAutomationQuery(
    {
      contactType,
      contactStage,
      contactStatus,
      contactSource,
      contactCreatedFilter,
      contactCreated,
      name,
      originator
    }: BuildYourOwnAutomation, 
    automationId: string,
  ) {
    return this.http.get < {
        result: {
          count: number
        }
      } > (this.apiHelper.fillUrl(
        'byoaQuery', {}, {
          data: encodeURIComponent(
            JSON.stringify({
              name,
              originator,
              contactType,
              contactStage,
              contactStatus,
              contactSource,
              contactCreatedFilter,
              contactCreated,
              automationId,
            })
          )
        },
      ))
      .pipe(map(data => {
        return data;
      }));
  }

  getRealEstateAutomation() {
    return this.http.get < any > (this.apiHelper.fillUrl('realestateAutomation', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateRealEstateAutomation(data) {
    return this.http.put < any > (this.apiHelper.fillUrl('realestateAutomation', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  upsertUserAutomations(id, data) {
    return this.http.post < { 
      result ? : BuildYourOwnAutomation[],
      errors ? : any
    } > (this.apiHelper.fillUrl('userAutomations', { id }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  // Update Lost Lead Trigger Code
  updateLostLeadInfo(id, data) {
    return this.http.put < any > (this.apiHelper.fillUrl('lostLeadAutomation', { id }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  // Update Delinquency Trigger Code
  updateDelinquencyInfo(id, data) {
    return this.http.put < any > (this.apiHelper.fillUrl('delinquencyAutomation', { id }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }
}