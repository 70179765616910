import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class YardiService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  verifyAndCreateYardiIntegration(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'yardi'}, {}), payload)
    .pipe(map(data => {
      return data;
    }));
  }

  getAllProspects() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiProspects', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getPropertyConfiguration() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiConfiguration', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getILSConfiguration() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiILSConfiguration', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getResidentPermissions() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiResidentPermissions', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  checkYardiWorkers(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiWorkers', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getYardiSources() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiSources', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getYardiSourcesQuery() {
    return this.http.get<any>(this.apiHelper.fillUrl('yardiSourcesQuery', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getYardiSourcesAdmin(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('yardiSource', {id: payload._id}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }
}
