<div class="page" id="messages" [ngStyle]="{'height': adjustedHeight}">
  <div *ngIf="registeredUserData.user.twilioAccountId" class="row full-height">
    <div class="conversation-sidebar col-md-2">
      <p-accordion #accordion (onOpen)="onTabOpen($event)" class="messaging-accordion">
        <p-accordionTab header="All Messages">
          <p class="message-filter-option" (click)="filterConversations('all', 'lastWeek')" [ngClass]="{'active': activeCategory === 'all' && activeGroup === 'lastWeek'}">This Week</p>
          <p class="message-filter-option" (click)="filterConversations('all', 'lastMonth')" [ngClass]="{'active': activeCategory === 'all' && activeGroup === 'lastMonth'}">This Month</p>
          <p class="message-filter-option" (click)="filterConversations('all', 'all')" [ngClass]="{'active': activeCategory === 'all' && activeGroup === 'all'}">All Time</p>
        </p-accordionTab>
        <p-accordionTab header="Awaiting Response">
          <p class="message-filter-option" (click)="filterConversations('awaiting', 'lastWeek')" [ngClass]="{'active': activeCategory === 'awaiting' && activeGroup === 'lastWeek'}">This Week</p>
          <p class="message-filter-option" (click)="filterConversations('awaiting', 'lastMonth')" [ngClass]="{'active': activeCategory === 'awaiting' && activeGroup === 'lastMonth'}">This Month</p>
          <p class="message-filter-option" (click)="filterConversations('awaiting', 'all')" [ngClass]="{'active': activeCategory === 'awaiting' && activeGroup === 'all'}">All Time</p>
        </p-accordionTab>
        <p-accordionTab>
          <p-header [ngClass]="{'unread': textMessagesService.getLocalUnreadCount() > 0}">
            Unread Messages
          </p-header>
          <p class="message-filter-option" (click)="filterConversations('unread', 'lastWeek')" [ngClass]="{'active': activeCategory === 'unread' && activeGroup === 'lastWeek'}">This Week</p>
          <p class="message-filter-option" (click)="filterConversations('unread', 'lastMonth')" [ngClass]="{'active': activeCategory === 'unread' && activeGroup === 'lastMonth'}">This Month</p>
          <p class="message-filter-option" (click)="filterConversations('unread', 'all')" [ngClass]="{'active': activeCategory === 'unread' && activeGroup === 'all'}">All Time</p>
        </p-accordionTab>
        <p-accordionTab header="Prospect Messages">
          <p class="message-filter-option" (click)="filterConversations('prospects', 'lastWeek')" [ngClass]="{'active': activeCategory === 'prospects' && activeGroup === 'lastWeek'}">This Week</p>
          <p class="message-filter-option" (click)="filterConversations('prospects', 'lastMonth')" [ngClass]="{'active': activeCategory === 'prospects' && activeGroup === 'lastMonth'}">This Month</p>
          <p class="message-filter-option" (click)="filterConversations('prospects', 'all')" [ngClass]="{'active': activeCategory === 'prospects' && activeGroup === 'all'}">All Time</p>
        </p-accordionTab>
        <p-accordionTab header="Resident Messages">
          <p class="message-filter-option" (click)="filterConversations('residents', 'lastWeek')" [ngClass]="{'active': activeCategory === 'residents' && activeGroup === 'lastWeek'}">This Week</p>
          <p class="message-filter-option" (click)="filterConversations('residents', 'lastMonth')" [ngClass]="{'active': activeCategory === 'residents' && activeGroup === 'lastMonth'}">This Month</p>
          <p class="message-filter-option" (click)="filterConversations('residents', 'all')" [ngClass]="{'active': activeCategory === 'residents' && activeGroup === 'all'}">All Time</p>
        </p-accordionTab>
    </p-accordion>
    </div>
    <div class="conversation-sidebar col-md-3">

      <div
        *ngFor="let thread of selectedConversationType"
        [ngClass]="{'unread': thread.read === false, 'active': selectedThread && (selectedThread._id === thread._id)}"
        class="conversations-row row"
        (click)="getConversation(thread)">
        <div class="col-md-2 user-icon">
          <i class="far fa-user"></i>
        </div>
        <div class="col-md-6">
          <p class="contact-name">{{thread.contact.firstName}}</p>
          <p class="truncate message-preview full-width">{{thread.body}}</p>
        </div>
        <div class="col-md-4">
          <span class="thread-date">{{thread.mostRecentActivity | date:'h:mm a'}}<br />{{thread.mostRecentActivity | date:'M/d/yy'}}</span>
        </div>
      </div>
      <div *ngIf="selectedConversationType.length === 0">
        <p class="text-center">No Messages</p>
      </div>
    </div>
    <div class="col-md-7 chat-wrapper" *ngIf="selectedThread">
      <div class="message-action-wrap">
        <h4 class="contact-click pull-left" (click)="goToContact(selectedThread.contact._id)">{{selectedThread.contact.contactIdentifier}} - {{selectedThread.contact.phoneNumber | phone}}</h4>
        <div class="button button-small pull-right button-dark" (click)="checkOptInStatus(selectedThread.contact._id)">Opt In/Out</div>
      </div>
      <div id="chat-window">
        <div class="chat-bubble-wrap" *ngFor="let message of activeConversation; let i = index">
          <p [ngClass]="{'from-them': message.from === selectedThread.contact.phoneNumber || message.from === 'visitor', 'from-me': message.to === selectedThread.contact.phoneNumber, 'from-bot': message.from === 'bot'}">
            {{message.body}}
          </p>
          <span [ngClass]="{'them-time': message.from === selectedThread.contact.phoneNumber || message.from === 'visitor',
              'me-time': message.to === selectedThread.contact.phoneNumber || message.to === 'user'}"
              class="timestamp">
            <ng-container *ngIf="message.from === 'bot'">
              <i class="far fa-user-robot"></i>
              ChatBot on&nbsp;
            </ng-container>
            {{message.updated | date:'MMM d, y, h:mm a'}}
          </span>
        </div>
      </div>
      <div class="reply-input">
        <div class="row align-items-end">
          <div class="col-md-9">
            <div class="input-group mb-3">
              <textarea rows="3" placeholder="Reply..." [(ngModel)]="replyMessage"
              [ngModelOptions]="{standalone: true}" class="form-control reply-field"></textarea>
            </div>
          </div>
          <div class="col-md-3">
            <button class="button button-dark button-small submit-button" [disabled]="sendingText" (click)="sendText($event)"><span *ngIf="sendingText" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7" *ngIf="!selectedThread">
      <div id="chat-window">
        <p class="text-center"><img src="/assets/no-messaging.png" /></p>
        <h3 class="text-center">
          Select a conversation to get started!
        </h3>
      </div>
    </div>
  </div>
  <div *ngIf="!registeredUserData.user.twilioAccountId" class="row messaging-disabled">
    <p class="text-center full-width margin-top-48">
      <img src="/assets/no-messaging.png" />
    </p>
    <h3 class="full-width margin-top-48">You do not have two way messaging enabled, please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
  </div>
</div>

<!-- Opt Out Modal -->
<ng-template #optOutModal let-optOutModal>
  <div class="modal-header">
      <h5 class="modal-title">{{optOutMessage}} Contact</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, optOutModal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
  </div>
  <div class="schedule-text-modal modal-body">
      <div class="alert alert-danger"><strong>Warning:</strong> You are about to {{optOutMessage}} this contact. Please be sure this is your intention.</div>
      <button (click)="changeOptInStatus(optOutModal, selectedThread.contact._id)" [disabled]="optOutInProgress" class="button button-dark">
          <span *ngIf="optOutInProgress" class="spinner-border spinner-white spinner-border-sm mr-1"></span>
          {{optOutMessage}} Contact
      </button>
  </div>
</ng-template>
