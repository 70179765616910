import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { Observable } from 'rxjs'
import { Contact } from '@nurtureboss/common/dist/types/contacts'

interface Thread {
  _id: string;
  created: string;
  updated: string;
  ownerId: string;
  threadId: string;
  threadSubject: string;
  assistantId: string;
  passedToOnsiteTeam: boolean;
  contactId: string;
};

interface ThreadWithContact extends Thread {
  contact: Partial<Contact>;
  recentMessage: string;
};

interface ThreadGrouping {
  _id: string;
  latestThread: ThreadWithContact;
  threads: Thread[];
};

type Result = {
  data: ThreadGrouping[];
  totalRecords: number;
};

export interface ThreadsApiResponse {
  result: Result;
  errors: string[]; 
};

@Injectable({ providedIn: 'root' })
export class ThreadsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getAllThreads(skip, take, query?, property?, startingDate?, endingDate?): Observable<ThreadsApiResponse> {
    return this.http.get<ThreadsApiResponse>(this.apiHelper.fillUrl('threads', {}, {
      skip: skip || 0,
      take: take || 25,
      query: query || '',
      property: property || '',
      startingDate: startingDate || '',
      endingDate: endingDate || '',
    }))
      .pipe(map(data => {
        return data;
      }));
  }

  getThread(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('thread', {id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  addMessageToThread(id, data) {
    return this.http.post<any>(this.apiHelper.fillUrl('threadMessages', {id}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  updateThread(id, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('thread', {id}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }
}
