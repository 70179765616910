<nb-modal
  [name]="name"
  [confetti]="false"
  [doOnDismiss]="contactTableColumnSelectorDismissedBuilder()"
  header="Choose Columns"
  class="contact-table-column-selector"
  [footer]="false"
>
  <div id="contact-table-column-selector-body">
    <div class="checkbox-container" (click)="toggleCheckbox('name')">
      <input class="checkbox" type="checkbox" ng-attr-id="name" [(ngModel)]="columns.name">
      <label class="checkbox-label" ng-attr-for="name">Name</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('created')">
      <input class="checkbox" type="checkbox" ng-attr-id="created" [(ngModel)]="columns.created">
      <label class="checkbox-label" ng-attr-for="created">Created</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('status')">
      <input class="checkbox" type="checkbox" ng-attr-id="status" [(ngModel)]="columns.status">
      <label class="checkbox-label" ng-attr-for="status">Status</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('type')">
      <input class="checkbox" type="checkbox" ng-attr-id="type" [(ngModel)]="columns.type">
      <label class="checkbox-label" ng-attr-for="type">Type</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('stage')">
      <input class="checkbox" type="checkbox" ng-attr-id="stage" [(ngModel)]="columns.stage">
      <label class="checkbox-label" ng-attr-for="stage">Stage</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('lastActivity')">
      <input class="checkbox" type="checkbox" ng-attr-id="lastActivity" [(ngModel)]="columns.lastActivity">
      <label class="checkbox-label" ng-attr-for="lastActivity">Last Activity</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('source')">
      <input class="checkbox" type="checkbox" ng-attr-id="source" [(ngModel)]="columns.source">
      <label class="checkbox-label" ng-attr-for="source">Source</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('beds')">
      <input class="checkbox" type="checkbox" ng-attr-id="beds" [(ngModel)]="columns.beds">
      <label class="checkbox-label" ng-attr-for="beds">Beds</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('commTypes')">
      <input class="checkbox" type="checkbox" ng-attr-id="commTypes" [(ngModel)]="columns.commTypes">
      <label class="checkbox-label" ng-attr-for="commTypes">Comm. Types</label>
    </div>
    <div class="checkbox-container" (click)="toggleCheckbox('renewedLeaseStartDate')">
      <input class="checkbox" type="checkbox" ng-attr-id="renewedLeaseStartDate" [(ngModel)]="columns.renewedLeaseStartDate">
      <label class="checkbox-label" ng-attr-for="renewedLeaseStartDate">Renewal Date</label>
    </div>
  </div>
</nb-modal>