// Angular:
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Libs:
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Services:
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';
import { EntrataService } from './entrata.service';
import { RealPageService } from './realPage.service';
import { YardiService } from './yardi.service';

// Types:
import { IProperty, IIntegrationDetails, RentgrataListing, RentgrataListingsResponse, IntegrationPayload } from '@app/_types/integration.types';

// The future of user integration management on the UI.
@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService,
    private authService: AuthenticationService,
    private yardiService: YardiService,
    private realPageService: RealPageService,
    private entrataService: EntrataService,
  ) { }

  public get isKnockUser() {
    return this.hasIntegration('knock');
  }

  public get isYardiUser() {
    return this.hasIntegration('yardi');
  }

  public get isResmanUser() {
    return this.hasIntegration('resman');
  }

  public get isACEUser() {
    return this.hasIntegration('ace');
  }

  public get isRealPageUser() {
    return this.hasIntegration('realpage');
  }

  public get isRentCafeUser() {
    return this.hasIntegration('rentcafe');
  }

  public get isRentgrataUser() {
    return this.hasIntegration('rentgrata');
  }

  public get isEntrataUser() {
    return this.hasIntegration('entrata');
  }

  public get isRentDynamicsUser() {
    return this.hasIntegration('rentdynamics');
  }

  public get isAdmin() {
    return this.hasIntegration('admin');
  }

  public get isPartner() {
    return this.hasIntegration('partner');
  }

  public get isDev() {
    return this.hasIntegration('dev');
  }

  public get hasBot() {
    return this.hasIntegration('bot');
  }

  public get hasScheduleATourPermission() {
    return this.hasIntegration('schedule-a-tour');
  }

  public get hasWidgetPermissions(): boolean {
    return this.isRentCafeUser || this.isRealPageUser || this.isEntrataUser || this.isKnockUser || this.isRentDynamicsUser || this.isYardiUser;
  }

  public get isIntegratedUser(): boolean {
    return this.isYardiUser || this.isRealPageUser || this.isEntrataUser || this.isResmanUser;
  }

  public hasIntegration(name: string): boolean {
    return this.authService.currentUserValue.user.claims.includes(name);
  }

  public getPropertyInfo(propertyId): Observable<IProperty> {
    return this.http.get<{ result: IProperty }>(
      this.apiHelper.fillUrl('integrationProperty', { propertyId }, {}),
    ).pipe(map(data => data.result));
  }

  public updatePropertyInfo(propertyId, data): Observable<IProperty> {
    return this.http.put<{ result: IProperty }>(
      this.apiHelper.fillUrl('integrationProperty', { propertyId }, {}),
      data,
    ).pipe(map(data => data.result));
  }

  public getYardiIntegrationDetails(_propertyId): Observable<Readonly<IIntegrationDetails>> {
    const observableList = [];
    observableList.push(this.yardiService.getYardiSources());
    observableList.push(this.yardiService.getILSConfiguration());
    observableList.push(this.yardiService.getResidentPermissions());
    return new Observable<IIntegrationDetails>((subscriber) => {

      // TODO: Type output
      forkJoin(observableList).subscribe((output: Array<any>) => {
        const details: Partial<IIntegrationDetails> = {};
        details.agents = output[0].result.agents;
        details.sources = output[0].result.sources;
        details.ILSConfig = output[1].result.configuration;
        details.residentPermissions = output[2].result;
        subscriber.next(details as IIntegrationDetails);
      }, (err) => {
        subscriber.error(err);
      }, () => subscriber.complete());
    });
  }

  public getRealPageIntegrationDetails(_propertyId): Observable<Readonly<IIntegrationDetails>> {
    const observableList = [];
    observableList.push(this.realPageService.getLeasingAgents());
    observableList.push(this.realPageService.getSources());
    return new Observable<IIntegrationDetails>((subscriber) => {

      // TODO: Type output
      forkJoin(observableList).subscribe((output: Array<any>) => {
        const details: Partial<IIntegrationDetails> = {};
        details.agents = output[0].result;
        details.sources = output[1].result;
        subscriber.next(details as IIntegrationDetails);
      }, (err) => {
        subscriber.error(err);
      }, () => subscriber.complete());
    });
  }

  public getEntrataIntegrationDetails(_propertyId): Observable<Readonly<IIntegrationDetails>> {
    const observableList = [];
    observableList.push(this.entrataService.getLeasingAgents());
    observableList.push(this.entrataService.getSources());
    observableList.push(this.entrataService.getEventTypes())
    return new Observable<IIntegrationDetails>((subscriber) => {

      // TODO: Type output
      forkJoin(observableList).subscribe((output: Array<any>) => {
        const details: Partial<IIntegrationDetails> = {};
        details.agents = output[0].result;
        details.sources = output[1].result;
        details.eventTypeLists = output[2].result;
        subscriber.next(details as IIntegrationDetails);
      }, (err) => {
        subscriber.error(err);
      }, () => subscriber.complete());
    });
  }

  public saveIntegrationDetails(propertyId: string, integrationType: string, data: IntegrationPayload): Observable<IProperty> {
    return this.http.put<any>(
      this.apiHelper.fillUrl('updateIntegrationProperty', { propertyId, integrationType }, {}),
      data,
    ).pipe(map(resData => resData.result));
  }

  public getKnockCommunities(): Observable<any> {
    return this.http.get<{ result: any }>(
      this.apiHelper.fillUrl('getKnockCommunities', {}, {}),
    ).pipe(map(data => data.result));
  }

  public getRentgrataListings(): Observable<RentgrataListing[]> {
    return this.http.get<RentgrataListingsResponse>(
      this.apiHelper.fillUrl('getRentgrataListings', {}, {}),
    ).pipe(map(response => response.result));
  }

  public getKnockCommunityAgents(id): Observable<any> {
    return this.http.get<{ result: any }>(
      this.apiHelper.fillUrl('getKnockCommunityAgents', {id}, {}),
    ).pipe(map(data => data.result));
  }

  public createRentCafeIntegration() {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'rentcafe'}, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  public createKnockIntegration() {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'knock'}, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  public createRentgrataIntegration(): Observable<any> {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'rentgrata'}, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  public createRentDynamicsIntegration() {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'rentdynamics'}, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  public createACEIntegration(){
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'ace'}, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  public getEntrataProperties(data): Observable<any> {
    return this.http.post<{ result: any }>(
      this.apiHelper.fillUrl('getEntrataProperties', {}, {}),
      data
    ).pipe(map(response => response.result));
  }

  getLeasingAgents(propertyId, integrationType) {
    return this.http.get<any>(this.apiHelper.fillUrl('leasingAgents', { propertyId, integrationType }, {}))
    .pipe(map(data => {
      return data;
    }));
  }

  getSources(propertyId, integrationType) {
    return this.http.get<any>(this.apiHelper.fillUrl('sources', { propertyId, integrationType }, {}))
    .pipe(map(data => {
      return data;
    }));
  }

  getLeaseRenewalPermission(propertyId) {
    return this.http.get<any>(this.apiHelper.fillUrl('getLeaseRenewalPermission', { propertyId }, {}))
    .pipe(map(data => {
      return data;
    }));
  }

  public getLostReasons(propertyId, integrationType) {
    return this.http.get<any>(this.apiHelper.fillUrl('lostReasons', { propertyId, integrationType }, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }

  getLedgerDataPermission(propertyId) {
    return this.http.get<any>(this.apiHelper.fillUrl('getLedgerDataPermission', { propertyId }, {}))
    .pipe(map(data => {
      return data;
    }));
  }

  public getResmanIntegrationDetails(propertyId): Observable<Readonly<IIntegrationDetails>> {
    const observableList = [];
    observableList.push(this.getLeasingAgents(propertyId, 'resman'));
    observableList.push(this.getSources(propertyId, 'resman'));
    observableList.push(this.getLostReasons(propertyId, 'resman'));
    return new Observable<IIntegrationDetails>((subscriber) => {

      // TODO: Type output
      forkJoin(observableList).subscribe((output: Array<any>) => {
        const details: Partial<IIntegrationDetails> = {};
        details.agents = output[0].result;
        details.sources = output[1].result;
        details.lostReasons = output[2].result;
        subscriber.next(details as IIntegrationDetails);
      }, (err) => {
        subscriber.error(err);
      }, () => subscriber.complete());
    });
  }
}
