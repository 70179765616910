<div class="page-title">
  <h2>Account</h2>
</div>

<!-- ACCOUNT INFORMATION -->
<div class="page">
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">Account Information</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateUser()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Save Settings
    </button>
  </div>
  <div class="user-table">
    <div>
      <h6>Username:</h6>
      {{userData.email}}
    </div>
    <div>
      <h6>Timezone:</h6>
      <div class="form-group">
        <select class="form-control" [(ngModel)]="userData.timezone" [ngModelOptions]="{standalone: true}">
          <option value="America/Los_Angeles">America/Los_Angeles (Pacific)</option>
          <option value="America/Phoenix">America/Phoenix</option>
          <option value="America/Denver">America/Denver (Mountain)</option>
          <option value="America/Chicago">America/Chicago (Central)</option>
          <option value="America/New_York">America/New_York (Eastern)</option>
        </select>
      </div>
    </div>
    <div *ngIf="localUserData.user.claims.indexOf('real estate') === -1 || localUserData.user.claims.indexOf('admin') > -1">
      <h6>Property Name:</h6>
      <span *ngIf="localUserData.user.claims.indexOf('admin') === -1">{{userData.propertyName}}</span>
      <!-- Intentionally do not expose to Partners. -->
      <input *ngIf="localUserData.user.claims.indexOf('admin') > -1"  type="text" class="form-control" [(ngModel)]="userData.propertyName" [ngModelOptions]="{standalone: true}" id="propertyName" aria-describedby="propertyName">
    </div>
    <!-- Intentionally do not expose to Partners. -->
    <div *ngIf="localUserData.user.claims.indexOf('real estate') === -1 || localUserData.user.claims.indexOf('admin') > -1">
      <h6>Property Address:</h6>
      {{userData.address}}
    </div>
    <div>
      <h6>Texting Phone Number:</h6>
      {{userData.assignedNumber | phone}}
    </div>
  </div>
</div>

<!-- ACCOUNT SETTINGS -->
<div class="page">
  <div class="overflow-auto margin-bottom-24">
  <h6 class="title pull-left">Account Settings</h6>
  <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateUser()">
    <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
    Save Settings
  </button>
  </div>
  <div class="user-table">
    <div class="no-border">
      <h6>One-Way Messaging</h6>
      <label class="switch">
      <input type="checkbox" [(ngModel)]="userData.oneWay" (change)="updateUser()">
      <span class="slider round"></span>
      </label>
    </div>
    <div class="form-group">
      <label>One Way Auto Responder</label>
      <input [readonly]="!userData.oneWay" type="text" class="form-control" [(ngModel)]="userData.oneWayResponse" [ngModelOptions]="{standalone: true}" id="oneWayResponse" aria-describedby="oneWayResponse" placeholder="Thanks for reaching out...">
    </div>
    <div class="no-border">
      <h6>Call Forwarding <i placement="top" ngbTooltip="Calls made to your texting number will be forwarded to the number provided here." class="far fa-info-circle"></i></h6>
      <label class="switch">
      <input type="checkbox" [(ngModel)]="userData.callForwarding" (change)="updateUser()">
      <span class="slider round"></span>
      </label>
    </div>
    <div class="form-group">
      <label>Call Forwarding Number</label>
      <input [readonly]="!userData.callForwarding" type="text" class="form-control" [(ngModel)]="userData.callForwardingNumber" [ngModelOptions]="{standalone: true}" id="callForwardingNumber" aria-describedby="callForwardingNumber" placeholder="6025551234">
    </div>
    <div class="no-border">
      <h6>Safe Send <i placement="top" ngbTooltip="Enabling safe send will prevent multiple text messages to the same phone number within 16 hours." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="userData.safeSend" (change)="updateUser()">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="form-group">
      <h6>Email From Address:</h6>
      <div class="email-from-address">
        <input (keyup)="removeAtSignChars()" type="text" class="form-control" [(ngModel)]="userData.emailConfig.routingAddress" [ngModelOptions]="{standalone: true}" autocomplete="off" id="emailFromAddress" aria-describedby="emailFromAddress" placeholder="From address for your property">
        <div class="apt-leasing-email-append">@apt-leasing.info</div>
      </div>
    </div>
    <div class="no-border">
      <h6>Show FHEO logo in Footer <i placement="top" ngbTooltip="Determines whether the Office of Fair Housing and Equal Opportunity logo will be included in the footer of emails and Nurture Pages sent out on behalf of the property. This option should not be turned off for properties based in the United States." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="userData.fheo" (change)="updateUser()">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</div>

<!-- COMMUNICATION SETTINGS -->
<div class="page">
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">Communication Settings</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateSettings(true)">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Save Communication
    </button>
  </div>
  <div class="user-table">
    <div class="no-border">
      <h6>Show Email Us Links <i placement="top" ngbTooltip="Determines whether the Email Us link/button will be included in the emails and Nurture Pages sent out on behalf of the property." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="templateSettings.showEmailUsLinks" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="no-border">
      <h6>Show Visit Website Links <i placement="top" ngbTooltip="Determines whether the Visit Website link/button will be included in the footer of emails and Nurture Pages sent out on behalf of the property." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="templateSettings.showVisitWebsiteLinks" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="no-border">
      <h6>Show Call Us Links <i placement="top" ngbTooltip="Determines whether the Call Us link/button will be included in emails and Nurture Pages sent out on behalf of the property." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="templateSettings.showCallUsLinks" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="no-border">
      <h6>Send Plain Text Emails to Outlook and Hotmail <i placement="top" ngbTooltip="Send only plain text emails to outlook and hotmail email addresses." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="templateSettings.sendOutlookHotmailPlainText" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="no-border">
      <h6>Show Translate Link <i placement="top" ngbTooltip="Determines whether to show link to translate emails and nurture page" class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="templateSettings.showTranslateLink" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="no-border">
      <h6>Translation Language <i placement="top" ngbTooltip="Language to convert emails and nurture pages to with translate link." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <select class="form-control translation-language" [(ngModel)]="templateSettings.translationLanguage" [ngModelOptions]="{standalone: true}">
          <option value="es">Spanish</option>
        </select>
      </label>
    </div>
    <div class="no-border">
      <h6>Pause For Inbound Message <i placement="top" ngbTooltip="Your automations can be aware of when a prospect has reached out to the onsite team and is waiting for a response. By enabling ‘Pause For Inbound Message’ the automation will pause when the most recent activity is an inbound message from a prospect. The automation will remain paused until this is no longer the most recent activity." class="far fa-info-circle"></i></h6>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="integrationSettings.pauseForInboundMessage" (change)="updateSettings(true)">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</div>

<!-- BRANDING SETTINGS -->
<div class="page">
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">Custom Branding</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="saveUserBranding()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Save Branding
    </button>
  </div>
  <div class="user-table">
    <div class="form-group">
      <h6>Primary Color</h6>
      <input type="text" class="form-control margin-bottom-12" pattern="^#(?:[0-9a-fA-F]{3}){1,2}$" [(ngModel)]="brandings.primaryColor" [ngModelOptions]="{standalone: true}" id="colorPicker" aria-describedby="colorPicker">
      <div class="error-text no-border margin-top-12 margin-bottom-12" *ngIf="hexInvalid">
        Please specify a valid hex color
      </div>
      <p-colorPicker [(ngModel)]="brandings.primaryColor" [inline]="true"></p-colorPicker>
    </div>
    <div class="no-border">
      <h6>Font Family:</h6>
      <div class="form-group">
        <p-autoComplete
          styleClass="form-control form-autocomplete font-selection"
          (onSelect)="onFontSelect($event)"
          (onClear)="onFontCleared($event)"
          [dropdown]="true"
          [suggestions]="fontSuggestions"
          (completeMethod)="filterFonts($event)"
          [forceSelection]="true"
          [completeOnFocus]="true"
          [placeholder]="brandings.fontFamily"
          field="family"
        >
          <ng-template let-font pTemplate="item">
            <span>{{font.family}}</span>
          </ng-template>
        </p-autoComplete>
      </div>
      <span id="samplefont">Almost before we knew it, we had left the ground.</span>
    </div>
  </div>
</div>

<!-- USER ACCESS SETTINGS -->
<div class="page" *ngIf="localUserData.user.claims.includes('admin') || localUserData.user.claims.includes('partner')">
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">User Access</h6>
  </div>
  <div class="user-table">
    <div class="no-border">
      <h6 class="title">Login Email</h6>
      <div class="no-border">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Email Address" [(ngModel)]="userData.email">
        </div>
        <button class="button button-light margin-top-24" [disabled]="savingSettings" (click)="updateUserEmail()">Update User Email</button>
      </div>
    </div>
    <div class="no-border">
      <h6 class="title">Change Password</h6>
      <div class="no-border">
        <div class="input-group">
          <input id="password" type="password" class="form-control" placeholder="New Password"
            [(ngModel)]="userData.password">
          <app-toggle-visible-password elemId="password" [value]="userData.password" [insideInput]="true"></app-toggle-visible-password>
        </div>
        <button class="button button-light margin-top-24" [disabled]="savingSettings" (click)="updateUserPassword()">Update User Password</button>
      </div>
    </div>
  </div>
</div>

<!-- Intentionally do not expose to Partner. -->
<!-- USER ACCESS SETTINGS -->
<div class="page" *ngIf="localUserData.user.claims.includes('admin')">
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">Onboarding Emails</h6>
  </div>
  <div class="user-table">
    <div class="no-border">
      <div class="no-border row no-margin">
        <div class="no-border col-md3">
          <div class="button button-light margin-bottom-24 margin-top-12" (click)="openModal(onboardingEmailConfirm, true)">Onboarding Invite</div>
          <div class="button button-light margin-bottom-24 margin-top-12" (click)="openModal(onboardingFollowUpEmailConfirm, false)">Onboarding Follow Up</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #onboardingEmailConfirm let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Send Onboarding Email</h5>
  </div>
  <div class="modal-body">
    <p>You are about to send an onboarding email to <strong>{{userData.email}}</strong>!</p>
    <button (click)="sendOnboardingEmail(modal)" [disabled]="savingSettings" class="margin-top-48 button button-dark">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Send
    </button>
    <button (click)="modal.dismiss()" [disabled]="savingSettings" class="button button-white margin-top-12 margin-left-12">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #onboardingFollowUpEmailConfirm let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Send Onboarding Follow Up Email</h5>
  </div>
  <div class="modal-body">
    <p>You are about to send a follow up onboarding email to <strong>{{userData.email}}</strong>!</p>
    <button (click)="sendOnboardingFollowUpEmail(modal)" [disabled]="savingSettings" class="margin-top-48 button button-dark">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Send
    </button>
    <button (click)="modal.dismiss()" [disabled]="savingSettings" class="button button-white margin-top-12 margin-left-12">
      Cancel
    </button>
  </div>
</ng-template>