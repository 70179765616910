<div class="page dynamic-height" id="automation">
  <div class="card">
    <div class="card-body">

      <!-- No Integration Enabled -->
      <div *ngIf="!canUserUseAutomations()" class="row">
        <p class="text-center full-width margin-top-48">
          <img src="/assets/no-messaging.png" />
        </p>
        <h3 class="text-center full-width margin-top-48">You do not have your PMS integration enabled. Please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
      </div>

      <!-- Integrated Automation Tracks -->
      <div *ngIf="canUserUseAutomations()" class="sequence-wrapper">
        <div class="row">
          <div class="col-md-3 sequence-options-column" *ngIf="subPage !== 'vla'">
            <div class="sequence-option-wrapper">
              <h5>Create New Automation</h5>
              <div class="thumbnail new-automation margin-top-48">
                <img src="/assets/create-automation-icon.png" />
              </div>
              <p><strong>Create your own custom automation to work just the way you want.</strong></p>
              <button (click)="newBuildYourOwn()" class="button button-white">
                Create New
              </button>
            </div>
          </div>

          <!-- System automation tracks -->
          <div *ngFor="let automation of systemAutomations" class="col-md-3 sequence-options-column">
            <div class="sequence-option-wrapper">
              <h5>{{automation.name}}</h5>
              <div class="track-status-wrapper">
                <div class="active-indicator">
                  <span>{{automation.isActive ? 'ON' : 'OFF'}}</span>
                  <div class="track-status {{automation.isActive ? 'active' : 'not-active'}}"></div>
                </div>
              </div>
              <div class="thumbnail">
                <img *ngIf="automation.name === 'Rent Reminder Automation'" src="/assets/rent-reminder-automation-icon.png" />
                <img *ngIf="automation.name === 'Post Tour Automation'" src="/assets/post-tour-automation-icon.png" />
                <img *ngIf="automation.name === 'Pre Tour Automation'" src="/assets/pre-tour-automation-icon.png" />
                <img *ngIf="automation.name === 'Interest List Automation'" src="/assets/interest-list-automation-icon.png" />
                <img *ngIf="automation.name === 'Resident Outreach Automation'" src="/assets/resident-outreach-automation-icon.png" />
                <img *ngIf="automation.name === 'Lost Lead Automation'" src="/assets/lost-lead-automation-icon.png" />
                <img *ngIf="automation.name === 'Lease Renewal Automation'" src="/assets/lease-renewal-automation-icon.png" />
                <img *ngIf="automation.name === 'Appointment Reminder Automation'" src="/assets/appointment-reminder-automation-icon.png" />
                <img *ngIf="automation.name === 'Delinquency Automation'" src="/assets/delinquency-automation.png" />
                <img *ngIf="automation.name === 'Text Opt-In Automation'" src="/assets/opt-in-automation.png" />
                <img *ngIf="automation.isVLA && automation.name === 'Pre Tour VLA'" src="/assets/vla-pre-tour-automation.png" />
                <img *ngIf="automation.isVLA && automation.name === 'Post Tour VLA'" src="/assets/vla-pre-tour-automation.png" />
              </div>
              <p><strong>{{automation.description}}</strong></p>
              <button (click)="configureBuildYourOwn(automation.id)" class="button button-white">
                <i class="far fa-robot"></i> Configure
              </button>
            </div>
          </div>

          <!-- Price Drop Alert -->
          <div *ngIf="alerts && alerts['price-drop-alert'] && showAlerts" class="col-md-3 sequence-options-column">
            <div class="sequence-option-wrapper">
              <h5>Price Drop Alert</h5>
              <div class="track-status-wrapper">
                <div class="active-indicator">
                  <span>{{alerts['price-drop-alert'].active ? 'ON' : 'OFF'}}</span> 
                  <div class="track-status {{(alerts && alerts['price-drop-alert'].active) ? 'active' : 'not-active'}}"></div>
                </div>
              </div>
              <div class="thumbnail">
                <img src="/assets/price-drop-alerts.png" />
              </div>
              <p><strong>Alert contacts of price drops</strong></p>
              <button [disabled]="!property.availabilitySource" (click)="configureAlert('price-drop')" class="button button-white margin-right-12">
                <i class="far fa-robot"></i> Configure
              </button>
            </div>
          </div>

          <!-- Availability Alert -->
          <div *ngIf="alerts && alerts['availability-alert'] && showAlerts" class="col-md-3 sequence-options-column">
            <div class="sequence-option-wrapper">
              <h5>Availability Alert</h5>
              <div class="track-status-wrapper">
                <div class="active-indicator">
                  <span>{{alerts['availability-alert'].active ? 'ON' : 'OFF'}}</span>
                  <div class="track-status {{(alerts && alerts['availability-alert'].active) ? 'active' : 'not-active'}}"></div>
                </div>
              </div>
              <div class="thumbnail">
                <img src="/assets/availability-alerts.png" />
              </div>
              <p><strong>Alert contacts when properties become available</strong></p>
              <button [disabled]="!property.availabilitySource" (click)="configureAlert('availability')" class="button button-white margin-right-12">
                <i class="far fa-robot"></i> Configure
              </button>
            </div>
          </div>

          <!-- Custom automation tracks -->
          <div *ngFor="let automation of buildYourOwnAutomations" class="col-md-3 sequence-options-column">
            <div class="sequence-option-wrapper">
              <h5>{{automation.name}}</h5>
              <div class="track-status-wrapper">
                <div class="delete-indicator">
                  <div class="delete-automation-track-wrapper">
                    <i (click)="deleteBuildYourOwn(automation)" class="far fa-trash-alt"></i>
                  </div>
                </div>
                <div class="active-indicator">
                  <span>{{automation.isActive ? 'ON' : 'OFF'}}</span>
                  <div class="track-status {{automation.isActive ? 'active' : 'not-active'}}"></div>
                </div>
              </div>
              <div class="thumbnail">
                <img src="/assets/custom-automation-icon-2.png" />
              </div>
              <p><strong>Custom Automation</strong></p>
              <button (click)="configureBuildYourOwn(automation.id)" class="button button-white margin-right-12">
                <i class="far fa-robot"></i> Configure
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nb-modal
  [name]="'delete-byoa-modal'"
  [header]="'Delete Automation'"
  [confetti]="false"
  class="delete-automation-modal-wrapper"
>
  <div class="delete-automation">
    <div class="alert alert-danger">
      Are you sure you want to delete the <strong>{{selectedAutomationName}}</strong> automation?
    </div>
    <p>
      This will not cancel any existing scheduled messages created by this automation, however no more future messages will be sent!
    </p>
    <div class="d-flex p-2 justify-content-between">
      <button class="button button-white no-margin" (click)="closeModal('delete-byoa-modal');">Cancel</button>
      <button class="button button-red no-margin" (click)="confirmDeleteAutomation(selectedAutomationId)">Delete</button>
    </div>
  </div>
</nb-modal>