// Angular:
import { Component} from '@angular/core';
// Libs:
import { startCase } from 'lodash';
// Services:
import { AuthenticationService, ToastService, LoaderService, UsersService } from '@app/_services';
import { NotificationsService, Notifications } from '@app/_services/notifications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DayOfTheWeek, UnreadMessagesCadence } from '@nurtureboss/common/dist/types/notifications'

// TODO: Move to constants
const SCORECARDS_CADENCE_OPTIONS = ['weekly' as const, 'monthly' as const];
const CUSTOM_MODAL_STYLE = 'custom-modal-styles-new';

type DefaultNotifications = Omit<Notifications, 'id' | 'ownerId'| 'updated' | 'created'> & {
  _id?: string
};

const defaultNotifications = {
  _id: null,
  reportsReminder: {
    enabled: false,
    customMessage: '',
    emails: [],
  },
  scorecards: {
    cadence: SCORECARDS_CADENCE_OPTIONS[0],
    enabled: true,
    dayOfTheWeek: DayOfTheWeek.monday,
    dataStartDate: null,
    emails: [],
  },
  invoiceReminders: {
    enabled: true,
    emails: [],
  },
  rentReminders: {
    enabled: false,
    emails: [],
  },
  unreadMessages: {
    enabled: true,
    emails: [],
    cadence: UnreadMessagesCadence.DAILY,
    lastSent: null,
  }
};

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent {
  startCase = startCase;
  userData: any;
  notifications: DefaultNotifications = defaultNotifications;
  savingsNotifications = false;
  scorecardsCadenceOptions = SCORECARDS_CADENCE_OPTIONS
  unreadMessagesCadenceOptions = Object.values(UnreadMessagesCadence).map( (value) => ({ value, label: startCase(value) }) );
  isTwoWay = false;
  editorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
    ]
  };

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private modalService: NgbModal,
  ) {
      this.userData = this.authService.currentUserValue;
      this.loadData();
  }

  loadData() {
    this.loaderService.triggerLoader();
    this.notificationsService.getNotifications().subscribe((data) => {
      if (data && data.result) {
        for (const key of Object.keys(defaultNotifications)) {
          this.notifications[key] = data.result[key] ? data.result[key] : this.notifications[key];
        }
      }
      this.loaderService.stopLoader();
    }, (err) => {
      this.toastService.showError('There was an error retrieving your notifications');
      this.loaderService.stopLoader();
    });
    this.isTwoWay = !this.userData.oneWay;
  }

  updateUserNotifications(modal?) {
    this.savingsNotifications = true;
    this.notifications.scorecards.emails = Array.from(new Set(this.notifications.scorecards.emails));
    this.notifications.reportsReminder.emails = Array.from(new Set(this.notifications.reportsReminder.emails));
    this.notifications.invoiceReminders.emails = Array.from(new Set(this.notifications.invoiceReminders.emails));
    this.notifications.rentReminders.emails = Array.from(new Set(this.notifications.rentReminders.emails));
    this.notifications.unreadMessages.emails = Array.from(new Set(this.notifications.unreadMessages.emails));
    this.notificationsService.updateNotification(this.notifications._id, this.notifications).subscribe(() => {
      if (modal) {
        modal.dismiss();
      }
      this.savingsNotifications = false;
      this.toastService.showSuccess('Notifications Updated!');
    }, (error) => {
      this.savingsNotifications = false;
      this.toastService.showError('There was an error updating notifications');
    });
  }

  updateNotificationEmails(modal?) {
    this.savingsNotifications = true;
    this.userData.user.notifications = Array.from(new Set(this.userData.user.notifications));
    this.usersService.updateUser(this.userData.user._id, { notifications: this.userData.user.notifications }).subscribe((user) => {
      if (modal) {
        modal.dismiss();
      }
      this.savingsNotifications = false;
      this.toastService.showSuccess('Notifications Updated!');
    }, (error) => {
      this.savingsNotifications = false;
      this.toastService.showError('There was an error updating notifications');
    });
  }

  /**
   * Used to exit integration configuration and enablement modals.
   *
   * @param e (Event) DOM event used to prevent default acitons.
   * @param modal (Modal) Modal that you are dismissing.
   */
  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  /**
   * Used to open the configuration modal for each notification setting type.
   *
   * @param modal (Modal) Modal that you want to open.
   */
  openNotificationConfiguration (modal) {

    // TODO: Need to reset any unsaved values when the modal is dismissed.
    this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
  }

}
