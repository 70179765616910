<div>
  <div class="page-title">
    <h2>Billing</h2>
  </div>
  <div class="page">
  
    <!-- PAYMENT METHODS SETTINGS -->
    <!-- Intentionally do not expose to Partners. -->
    <div *ngIf="userData.user.claims && (userData.user.claims.indexOf('selfpay') > -1 || userData.user.claims.indexOf('admin') > -1)">
      <h6 class="title">Payment Methods</h6>
      <div class="button button-light pull-right margin-bottom-24" (click)="addNewPaymentMethod(newCardModal)">
        New Card
      </div>
      <div *ngIf="loadingPaymentMethods" class="spinner-border spinner-border-sm"></div>
      <div *ngIf="!loadingPaymentMethods">
        <table class="subscription-table">
          <tr>
            <th>
              Brand
            </th>
            <th>
              Last 4 Digits
            </th>
            <th>
              Expiration
            </th>
            <!-- <th>
              Remove
            </th> -->
          </tr>
          <tr *ngFor="let data of paymentMethodsData">
            <td>
              {{data.card.brand | titlecase}}
            </td>
            <td>
              {{data.card.last4}}
            </td>
            <td>
              {{data.card.exp_month + '/' + data.card.exp_year}}
            </td>
            <!-- <td class="text-center">
              <i (click)="removeCard(data)" class="fas fa-times remove-card"></i>
            </td> -->
          </tr>
        </table>
      </div>
    </div>
  
    <!-- SUBSCRIPTION SETTINGS -->
    <h6 class="title">Subscription Information</h6>
    <div *ngIf="subscriptionLoading" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!subscriptionLoading">
      <table class="subscription-table">
        <tr>
          <th>
            Status
          </th>
          <th>
            Cycle Start
          </th>
          <th>
            Cycle Stop
          </th>
          <th>
            Texts Allocated
          </th>
          <th>
            Texts Remaining
          </th>
        </tr>
        <tr>
          <td>
            <!-- TODO: change color of circle depending on value -->
            <div class="customer-badge {{subscriptionData.color}}">{{subscriptionData.statusCorrected}}</div>
          </td>
          <td>
            {{subscriptionData.start | date}}
          </td>
          <td>
            {{subscriptionData.stop | date}}
          </td>
          <td>
            {{subscriptionData.allocatedTexts | number}}
          </td>
          <td>
            {{subscriptionData.remainingTexts | number}}
          </td>
        </tr>
      </table>
    </div>
  
    <!-- INVOICES -->
    <h6 class="title">Payment History</h6>
    <div *ngIf="loadingInvoices" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!loadingInvoices">
      <div class="subscription-table" id="payments">
        <div class="row" *ngFor="let invoice of invoiceData">
          <div class="col-sm-2 date">
            {{invoice.created * 1000 | date}}
          </div>
          <div class="col-sm-4">
            {{invoice.billing_reason | snakecase | titlecase}} 
            ({{invoice.period_start * 1000 | date}} to {{invoice.period_end * 1000 | date}})
          </div>
          <div class="amount col-sm-2">
            {{(invoice.amount_due / 100) | currency}}
          </div>
          <div class="amount col-sm-2">
            <div class="customer-badge {{invoice.color}}">{{invoice.status}}</div>
          </div>
          <div class="col-sm-2">
            <a [href]="invoice.invoice_pdf" target="_blank">Download Invoice</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #newCardModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Add A Payment Method</h5>
    </div>
    <div class="modal-body">
      <div class="form-group">
          <label>Credit Card Information</label>
          <div id="card-element" class="credit-card-element margin-top-24"></div>
      </div>
      <div id="card-errors"></div>
      <div (click)="paymentSubmit(modal)" [disabled]="savingCardLoaded" class="margin-top-48 button button-dark">
          <span *ngIf="savingCardLoaded" class="spinner-border spinner-border-sm mr-1"></span>
          Save Card
      </div>
      <div (click)="modal.dismiss()" [disabled]="savingCardLoaded" class="button button-white margin-top-12">
        Exit
    </div>
    </div>
  </ng-template>
</div>