import { Component, Input, OnInit, OnChanges, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Table } from 'primeng/table';
import { Calendar } from 'primeng/calendar';
import { FilterUtils } from 'primeng/utils';
import { Router } from '@angular/router';
import { ContactsService, LoaderService, ToastService } from '@app/_services';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import { Contact } from '@nurtureboss/common/dist/types/contacts';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { NurtureBossModalService } from '@app/_services/modal.service';

type FilterOption = {
  label: string;
  value: string;
};

type ContactRow = {
  _id: string;
  clientFullName: string;
  clientGuestCardId?: string;
  clientFirstName?: string;
  clientPhoneNumber?: string;
  clientEmailAddress?: string;
  stage: string;
  created: Date;
  status: string;
  type: string;
  recentActivityDate?: Date;
  recentActivityType?: string;
  markedForOptIn: string;
};

type OnButtonClick = (selectedContacts: ContactRow[]) => void;

interface IPaginate {
  first: number;
  last: number;
}

interface IContactQueryData {
  data: [], // TODO: Type.
  count: number,
  filterValues: {} // TODO: Type.
}

@Component({
  selector: 'app-contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.less']
})
export class ContactsTableComponent implements OnInit, OnChanges {

  @ViewChild('contactsTable') table: Table;
  @ViewChild('activityCalendar') activityCalendar: Calendar;
  @ViewChild('createdCalendar') createdCalendar: Calendar;
  @ViewChild('renewedLeaseStartDateCalendar') renewedLeaseStartDateCalendar: Calendar;

  @Input() user: {
    claims: string[];
    optIn: boolean;
    doubleOptIn: boolean;
  };
  @Input() linkToContactPage = false;
  @Input() loading = false;
  @Input() headerLabel: string;

  // TODO: These should likely be a child component that gets
  // injected into this component.
  @Input() primaryButtonText?: string;
  @Input() primaryButtonClick?: OnButtonClick;
  @Input() isPrimaryEnabled = true;

  @Input() secondaryButtonText?: string;
  @Input() secondaryButtonClick?: OnButtonClick;
  @Input() isSecondaryEnabled = true;

  @Input() ternaryButtonText?: string;
  @Input() ternaryButtonClick?: OnButtonClick;
  @Input() isTernaryEnabled = true;

  @Input() headerTooltip = '';

  @Output() onSelectionChanged = new EventEmitter<ContactRow[]>();

  @Input() allowRowSelect = true;

  @Input() textOnly = false;
  @Input() valuesOverride: any[];

  // This is used to let the table know that value overrides are on there way.
  @Input() hasValueOverride = false;

  emptyColspan = 5;
  claims: string[] = [];
  ilmActive = false;
  realPageActive = false;
  yardiActive = false;
  knockActive = false;
  entrataActive = false;
  resmanActive = false;
  parserActive = false;
  rowsPerPage = 25;
  totalRecords = 0;
  normalizedContacts: ContactRow[] = [];
  selectedContacts: ContactRow[] = [];

  // Filters
  dynamicFilterFields = {};
  dynamicFilterOptions: { [key: string]: FilterOption[] } = {};
  communicationFilterOptions: FilterOption[] = [
    {
      value: 'hasEmail',
      label: 'Has Email',
    },
    {
      value: 'hasOptIn',
      label: 'Has Opted In to Text',
    },
    {
      value: 'doesNotHaveOptIn',
      label: 'Has NOT Opted In to Text',
    }
  ];
  dateRange: Date[] = [];

  // New Get Contacts Code.
  pagination: IPaginate = {
    first: 1,
    last: 25
  };
  contacts = []; // TODO: Type
  filterValues = {}; // TODO: Type
  activeQuery:any = {}; // TODO: Type
  debouncedSearch: any; // TODO: Type
  columns = {
    name: true,
    created: true,
    status: true,
    type: true,
    stage: true,
    lastActivity: false,
    source: true,
    beds: false,
    commTypes: true,
    renewedLeaseStartDate: false,
  };

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private contactsService: ContactsService,
    private toastService: ToastService,
    private nbModalService: NurtureBossModalService,
  ) {
      this.debouncedSearch = _.debounce(this.searchFunc, 300);
  }

  ngOnInit(): void {
    this.loadCachedColumns();
    this.initUser();
    if (!this.hasValueOverride) {
      this.initContacts();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    
    // Special login to accomodate use of table in Broadcast Recipients view.
    if (changes?.valuesOverride?.currentValue?.length && !changes?.valuesOverride?.previousValue) {
      this.initContacts();
    }
    if (changes.contacts && changes.contacts.currentValue !== changes.contacts.previousValue) {
      this.initContacts();
    }
    if (changes.user && changes.user.currentValue !== changes.user.previousValue) {
      this.initUser();
    }
  }

  searchFunc(query: string, property: string): void {
    this.activeQuery[property] = query;
    this.initContacts(this.pagination.first, this.pagination.last);
  }

  showPrimaryButton() {
    return this.primaryButtonClick && this.primaryButtonText;
  }

  showSecondaryButton() {
    return this.secondaryButtonClick && this.secondaryButtonText;
  }

  showTernaryButton() {
    return this.ternaryButtonClick && this.ternaryButtonText;
  }

  loadCachedColumns() {
    const cachedColumns = localStorage.getItem('contactColumns');
    if (cachedColumns) {
      this.columns = JSON.parse(cachedColumns);
      if (!this.allowRowSelect) {
        this.emptyColspan = Object.keys(this.columns).length;
      } else {
        this.emptyColspan = Object.keys(this.columns).length + 1;
      }
    }
  }

  initUser() {
    if (this.user) {
      this.claims = [...this.user.claims];
    }

    this.ilmActive = this.claims.includes('ilm');

    // TODO: Need a better strategy to handle dual integrations.
    this.yardiActive = this.claims.includes('yardi') && !this.claims.includes('knock');
    this.entrataActive = this.claims.includes('entrata') && !this.claims.includes('knock');
    this.realPageActive = this.claims.includes('realpage') && !this.claims.includes('knock');
    this.resmanActive =  this.claims.includes('resman') && !this.claims.includes('knock');
    this.knockActive = this.claims.includes('knock');
    this.parserActive = this.claims.includes('parser');
  }

  async getContacts (skip: number, take: number): Promise<{ result: IContactQueryData }> {
    this.pagination.first = 1;
    this.pagination.last = 25;
    
    // Check for any forced filters
    if (this.textOnly) {
      this.activeQuery.markedForOptIn = true;
    }
    try {

      // Code to handle skip starting at 1, need to find a better solution.
      if (skip === 1) {
        skip = 0;
      }
      return await this.contactsService.getPaginatedContacts(skip, take, this.activeQuery).toPromise();
    } catch (e) {
      this.toastService.showError('There was an error loading contacts.');

      // In case loader is running.
      this.loaderService.stopLoader();
    }
  }

  async initContacts(skip = 0, take = 25) {
    
    // This is to support a very specific use-case of broadcast recipients.
    // When the table is being used for this reason we do not allow filtering either.
    if (this.valuesOverride) {
      this.contacts = this.valuesOverride;
      this.normalizeContacts();
      this.totalRecords = this.valuesOverride.length;
      this.pagination.first = 1;
      this.pagination.last = this.valuesOverride.length;
    } else {
      this.loaderService.triggerLoader();
      const contactResults: { result: IContactQueryData } = await this.getContacts(skip, take);
      this.contacts = contactResults.result.data;
      this.totalRecords = contactResults.result.count;
      this.filterValues = contactResults.result.filterValues;
      this.selectedContacts = [];
      this.normalizeContacts();
      this.pagination.first = this.normalizedContacts.length ? 1 : 0;
      this.pagination.last = this.normalizedContacts.length ? this.rowsPerPage : 0;
      this.setTableHeading();
      this.loaderService.stopLoader();
    }
  }

  normalizeContacts() { // TODO: Type.
    let filterOptionsMap = new Map<string, Set<string>>();
    if (!this.hasValueOverride) {
      for (const filterField in this.filterValues) {
        filterOptionsMap.set(filterField, new Set(this.filterValues[filterField]));
      }
  
      // Set up the unknown filters
      filterOptionsMap.get('source').add('Unknown');
      filterOptionsMap.get('type').add('Unknown');
      filterOptionsMap.get('status').add('Unknown');
    }

    const mapContact = (contact) => {
      const getGuestCardId = () => {

        // Check Knock first so we get the Knock Prospect ID
        // if they are integrated with Knock AND a PMS.
        if (this.knockActive) {
          return contact.knockProspectId;
        } else if (this.yardiActive) {
          return contact.yardiGuestCardId;
        } else if (this.realPageActive) {
          return contact.realPageGuestCardId;
        } else if (this.entrataActive) {
          return contact.entrataGuestCardId;
        } else if (this.resmanActive) {
          return contact.resmanPersonId;
        } else {
          return null;
        }
      };
      const getApplicationId = () => {
        if (this.entrataActive) {
          return contact.entrataApplicationId;
        } else {
          return null;
        }
      };
      const getApplicantId = () => {
        if (this.entrataActive) {
          return contact.entrataApplicantId;
        } else {
          return null;
        }
      };
      const communication = [];
      if (contact.phoneNumber) {
        communication.push('hasPhone');
      }
      if (contact.emailAddress) {
        communication.push('hasEmail');
      }
      const contactHasOptIn = contact.phoneNumber && contact.markedForOptIn;
      if (contactHasOptIn) {
        communication.push('hasOptIn');
      } else {
        communication.push('doesNotHaveOptIn');
      }
      return {
        _id: contact._id,
        clientFullName: contact.contactIdentifier,
        clientGuestCardId: getGuestCardId(),
        clientApplicationId: getApplicationId(),
        clientApplicantId: getApplicantId(),
        clientFirstName: contact.firstName,
        clientPhoneNumber: contact.phoneNumber,
        clientEmailAddress: contact.emailAddress,
        ledgerBalance: contact.ledgerBalance,
        stage: contact.type === 'resident' ? 'Resident' : (contact.stage || 'Unknown'),
        created: contact.created,
        desiredBedCount: contact.desiredBedCount,
        createdTimestamp: contact.created ? new Date(contact.created).getTime() : undefined,
        status: contact.status || 'Unknown',
        type: contact.type || 'Unknown',
        source: contact.source || 'Unknown',
        recentActivityDate: contact.recentActivityDate || contact.created,
        recentActivityTimestamp: contact.recentActivityDate ? new Date(contact.recentActivityDate).getTime() : undefined,
        recentActivityType: contact.recentActivityType,
        markedForOptIn: contact.markedForOptIn + '',
        renewedLeaseStartDate: contact.renewedLeaseStartDate ? new Date(contact.renewedLeaseStartDate).getTime() : undefined,
        communication,
        updated: contact.updated,
        updatedTimestamp: contact.updated ? new Date(contact.updated).getTime() : 0,
      };
    };

    // Note: we no longer filter out archived leads, this is where we can do that.
    this.normalizedContacts = (this.contacts || []).map(mapContact);

    if (!this.hasValueOverride) {
      for (const [filterField, optionsSet] of Array.from(filterOptionsMap.entries())) {
        this.dynamicFilterFields[filterField] = Array.from(optionsSet.values()).map((item) => ({
          label: item,
          value: item,
        }));
      }
    }
  }

  async paginate(event: LazyLoadEvent): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + Number(event.rows);
    try {
      const contactResults = await this.getContacts(event.first, event.rows);
      this.contacts = contactResults.result.data;
      this.totalRecords = contactResults.result.count;
      this.filterValues = contactResults.result.filterValues;
      this.selectedContacts = [];
      this.normalizeContacts();
      this.pagination.first = this.normalizedContacts.length ? 1 : 0;
      this.pagination.last = this.normalizedContacts.length ? this.rowsPerPage : 0;
      this.setTableHeading();
    } catch (e) {
      this.toastService.showError('There was an error loading contacts.');
    }
    this.loaderService.stopLoader();
  }

  setTableHeading() {
    this.pagination.first = this.table?._first ?? 1;
    this.pagination.last = this.table?._rows ?? 25;
    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  onFilterChange(event, column) {

    // Text inputs are event.data...
    this.activeQuery[column] = event.value || event.data;
    this.initContacts(this.pagination.first, this.pagination.last);
  }

  // Allows us to automationcally close the
  // calendar when selecting date ranges.
  deferCalendarToggle(calendar: Calendar) {
    setTimeout(() => {
      calendar.toggle();
    }, 200);
  }

  onDateSelect(date: Date, column: string) {
    if (this.dateRange.length === 0 || this.dateRange.length === 2) {
      this.dateRange = [date];
      return;
    } else {
      this.dateRange.push(date);
    }
    if (this.dateRange.length === 2) {
      if (column === 'created') {
        this.deferCalendarToggle(this.createdCalendar);
      } else if (column === 'renewedLeaseStartDate'){
        this.deferCalendarToggle(this.renewedLeaseStartDateCalendar);
      } else {
        this.deferCalendarToggle(this.activityCalendar);
      }
      this.dateRange.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      const startTime = this.dateRange[0].getTime();
      const endDate = new Date(this.dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      const endTime = endDate.getTime();
      if (column === 'renewedLeaseStartDate'){ 
        this.activeQuery.renewedLeaseStartDate = {
          startingDate: startTime,
          endingDate: endTime,
        };
      } else {
        this.activeQuery.created = {
          startingDate: startTime,
          endingDate: endTime,
        };
      }
      this.initContacts(this.pagination.first, this.pagination.last);
    }
  }

  clearDateFilter(column: string) {
    delete this.activeQuery[column];
    this.initContacts(this.pagination.first, this.pagination.last);
  }

  onCommunicationFilter({ value }) {
    this.table.filter(value, 'communication', 'array-some');
  }

  onButtonClick(event: MouseEvent, type: string) {
    event.preventDefault();

    if (type === 'primary' && this.primaryButtonClick) {
      this.primaryButtonClick(this.selectedContacts);
    } else if (type === 'secondary' && this.secondaryButtonClick) {
      this.secondaryButtonClick(this.selectedContacts);
    } else if (type === 'ternary' && this.ternaryButtonClick) {
      this.ternaryButtonClick(this.selectedContacts);
    }
  }

  viewContact(id, e) {
    e.preventDefault();
    this.router.navigate(['/contact'], { queryParams: { id, backTo: 'contacts'} });
  }

  onRowSelect(_event) {
    this.onSelectionChanged.emit(this.selectedContacts);
  }

  onRowUnselect(_event) {
    this.onSelectionChanged.emit(this.selectedContacts);
  }

  async onHeaderCheckboxToggle(_event) {

    // This is out select all, we first need to load all contacts to represent a complete list.
    this.loaderService.triggerLoader();
    const contactResults = await this.getContacts(0, this.totalRecords);
    this.contacts = contactResults.result.data;
    this.totalRecords = contactResults.result.count;
    this.filterValues = contactResults.result.filterValues;
    this.table._rows = this.totalRecords;
    this.normalizeContacts();
    this.selectedContacts = [...this.normalizedContacts];
    this.pagination.first = this.normalizedContacts.length ? 1 : 0;
    this.pagination.last = this.normalizedContacts.length ? this.totalRecords : 0;
    this.setTableHeading();
    this.onSelectionChanged.emit(this.selectedContacts);
    this.loaderService.stopLoader();
  }

  openColumnSelectorModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.nbModalService.open(MODAL_NAMES.CONTACT_TABLE_COLUMN_SELECTOR);
  }

  updateColumns(newColumns) {
    const columnQueryMap = {
      name: 'contactIdentifier',
      created: 'created',
      status: 'status',
      type: 'type',
      stage: 'stage',
      source: 'source',
      beds: 'desiredBedCount',
      commTypes: 'communicationFilter',
      renewedLeaseStartDate: 'renewedLeaseStartDate',
    }
    let removedColumn = null;

    // Use find to cut search after finding removed column
    Object.keys(this.columns).find(column => {
      if (this.columns[column] === true && newColumns[column] === false) {
        removedColumn = column;
        return true
      }
    });

    this.columns = newColumns;
    if (!this.allowRowSelect) {
      this.emptyColspan = Object.keys(this.columns).length;
    } else {
      this.emptyColspan = Object.keys(this.columns).length + 1;
    }

    if (removedColumn && this.activeQuery[columnQueryMap[removedColumn]]) {
      delete this.activeQuery[columnQueryMap[removedColumn]];
      this.initContacts(this.pagination.first, this.pagination.last);
    }
    localStorage.setItem("contactColumns", JSON.stringify(newColumns));
  }
}
